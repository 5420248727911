import React from "react";
import { startUploadingLeadFile } from "../assets/helpers/helper";
import moment from "moment-timezone";

class Upload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timeZones: []
    };
  }

  componentDidMount() {
    console.log(
      "current user time zone...",
      moment.tz.zone(moment.tz.guess()).abbr(360)
    );
    const tempTimeZone = [];
    moment.tz.names().map((element) => {
      const zoneAbbr = moment.tz.zone(element).abbr(360);
      const timeZoneObject = {
        timeZoneAbbr: zoneAbbr,
        timeZone: element
      };
      tempTimeZone.push(timeZoneObject);
    });
    this.setState({
      ...this.state,
      timeZones: [...tempTimeZone]
    });
  }

  setUploadFileProps = async (e) => {
    const { user, fetchLeadsOfLoggedinUser, alterComponentSelection } =
      this.props;
    const formData = new FormData();
    formData.append(e.target.name, e.target.files[0]);
    formData.append("tenant_id", user.tenant_id);
    formData.append("subtenant_id", user.subtenant_id);
    const uploadAudioFileResponse = await startUploadingLeadFile(formData);
    if (uploadAudioFileResponse.data === "success") {
      const fetchLeadsOfLoggedinUserResponse = await fetchLeadsOfLoggedinUser(
        user.email_id
      );
      if (fetchLeadsOfLoggedinUserResponse) {
        alterComponentSelection(1);
      }
    }
  };

  render() {
    const { timeZones } = this.state;
    return (
      <div className="card w-100 d-flex  align-items-center">
        <div className="w-100 card-header rx-card-header">Upload Leads</div>
        <div className="row w-100">
          <div className="col-md-12 row mt-2">
            <div className="col-md-3">Select File</div>
            <div className="col-md-9">
              <input
                onChange={(e) => this.setUploadFileProps(e)}
                className="btn form form-control"
                name="leadFile"
                type="file"
                accept=".csv"
              />
            </div>
          </div>
          <div className="col-md-12 row mt-2">
            <div className="col-md-3">Select Time Zone</div>
            <div className="col-md-9">
              <select
                name="timeZone"
                id="timeZone"
                className="form form-control"
              >
                {timeZones.map((element, index) => {
                  return (
                    <option value={element.timeZoneAbbr} key={index}>
                      {`${element.timeZoneAbbr} - ${element.timeZone}`}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Upload;
