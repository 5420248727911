import axios from "axios";
import { urls } from "./constant";
const { REACT_APP_API_BASE_URL } = process.env;

const requestHeader = (apiEndPoint, method, formData = {}) => {
  var config = {
    method: method,
    url: `${REACT_APP_API_BASE_URL}${apiEndPoint}`,
    headers: {
      "Content-Type": "application/json"
    },
    data: formData
  };

  return config;
};

export const getLoggedinUser = (email_id) => {
  var config = requestHeader(urls.loggedinUser.url, urls.loggedinUser.method, {
    email_id
  });
  return axios(config);
};
