import React from "react";
import moment from "moment";
import Lead from "./Lead/Lead";
import Upload from "./Upload/Upload";
import LeadDetails from "./LeadDetails/LeadDetails";
import {
  fetchFeedForLead,
  addFeedForLead,
  updateTaskForLead,
  getAllTasksFromSalesforce,
} from "./assets/helpers/helper";
import Leads from "./assets/images/leads.svg";
import Uploads from "./assets/images/uploads.svg";
import ChartIcon from "./assets/images/chart.png";
import Logo from "../Task/assets/images/logo.png";
import Logout from "../Task/assets/images/logout.png";
import Notification from "./assets/images/notification.png";
import "./style.css";
import { StatisticChartContainer } from "./Charts/StatisticalCharts";
import { SyncClient } from "twilio-sync";

class Task extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLead: { leadDetails: {}, comments: [] },
      currentLeadIndex: null,
      selectedComponetOrder: 1,
      isFetchingLeadComments: false,
      showNotificationDropdown: false,
      allTasks: {},
      notificationCount: 0,
      notifications: [],
    };
    this.socket;
  }

  componentDidMount() {
    const syncClient = new SyncClient(this.props.syncAccessToken);
    syncClient.document("nytro-call-notes-notifications").then((document) => {
      document.on("updated", (args) => {
        if (
          this.props.user.identity === args.data.userIdentity &&
          args.data.notificationType === "call"
        ) {
          this.fetchLeads(args.data.phoneNumber);
        }
        if (
          this.props.user.identity === args.data.userIdentity &&
          args.data.notificationType === "call_recording"
        ) {
          this.fetchLeads(args.data.phoneNumber);
          this.fetchAllTasks();
        }
      });
    });
    this.fetchAllTasks();
  }

  fetchAllTasks = async () => {
    const { sdfcDetails, leads } = this.props;
    let phoneNumbers = "";
    leads.forEach((lead, index) => {
      phoneNumbers += lead.number;
      if (index !== leads.length - 1) {
        phoneNumbers += ",";
      }
    });
    const { access_token, instance_url } = sdfcDetails;
    const fetchAllTasksResponse = await getAllTasksFromSalesforce(
      instance_url,
      access_token,
      phoneNumbers
    );
    if (fetchAllTasksResponse.status === 200) {
      let draftCount = 0;
      for (const phoneNumber in fetchAllTasksResponse.data) {
        const tasks = fetchAllTasksResponse.data[phoneNumber];
        // Count tasks with "Draft" status
        for (const task of tasks) {
          if (task.Status === "Draft") {
            draftCount++;
          }
        }
      }
      const notificationItems = [];

      Object.entries(fetchAllTasksResponse.data).forEach(
        ([phoneNumber, tasksList]) => {
          const filteredTasks = tasksList.filter(
            (task) => task.Status === "Draft"
          );
          const lead = this.props.leads.find(
            (lead) => lead.number === phoneNumber
          );
          if (filteredTasks.length > 0) {
            const obj = {
              lead_index: this.props.leads.findIndex((l) => l.id === lead.id),
              msg: `You have ${filteredTasks.length} pending call summary reviews for ${lead.name}.`,
              created_at: moment(
                filteredTasks[filteredTasks.length - 1].CreatedDate
              )
                .local()
                .format("llll"),
            };
            notificationItems.push(obj);
          }
        }
      );

      this.setState({
        ...this.state,
        notificationCount: draftCount,
        allTasks: fetchAllTasksResponse.data,
        notifications: notificationItems.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        ),
      });
    }
  };

  selectCurrentLead = async (leadIndex) => {
    const { leads } = this.props;

    this.setState(
      {
        ...this.state,
        currentLeadIndex: leadIndex,
        currentLead: {
          ...this.state.currentLead,
          leadDetails: leads[leadIndex],
        },
      },
      () => {
        this.fetchLeads(leads[leadIndex].number, true);
      }
    );
  };

  fetchLeads = async (leadNumber, loader = false) => {
    if (loader) {
      this.setState({ ...this.state, isFetchingLeadComments: true });
    }
    const { sdfcDetails } = this.props;
    const { access_token, instance_url } = sdfcDetails;
    const fetchFeedForLeadResponse = await fetchFeedForLead(
      instance_url,
      access_token,
      "Lead",
      leadNumber
    );
    if (this.state.currentLead.leadDetails.number === leadNumber) {
      this.setState({
        ...this.state,
        currentLead: {
          ...this.state.currentLead,
          comments:
            fetchFeedForLeadResponse.status === 200
              ? fetchFeedForLeadResponse.data.sort(function (a, b) {
                  return new Date(b.CreatedDate) - new Date(a.CreatedDate);
                })
              : [],
        },
        isFetchingLeadComments: false,
      });
    } else {
      this.setState({
        ...this.state,
        isFetchingLeadComments: false,
      });
    }
  };

  setCurrentLeadComment = async (phoneNumber, commentText, commentTitle) => {
    const { sdfcDetails } = this.props;
    const { access_token, instance_url } = sdfcDetails;
    const addFeedForLeadResponse = await addFeedForLead(
      instance_url,
      access_token,
      phoneNumber,
      commentText,
      commentTitle
    );
    if (addFeedForLeadResponse.status === 200) {
      this.fetchLeads(phoneNumber);
    }
  };

  setCurrentLeadTaskStatus = async (
    phoneNumber,
    conferenceSid,
    description,
    status
  ) => {
    const { sdfcDetails } = this.props;
    const { access_token, instance_url } = sdfcDetails;
    const updateTaskForLeadResponse = await updateTaskForLead(
      instance_url,
      access_token,
      conferenceSid,
      description,
      status
    );
    if (updateTaskForLeadResponse.status === 200) {
      this.fetchAllTasks();
      this.fetchLeads(phoneNumber);
    }
  };

  alterComponentSelection = (orderIndex) => {
    this.setState({ ...this.state, selectedComponetOrder: orderIndex });
  };

  toggleNotificationDropdown = () => {
    this.setState((prevState) => ({
      showNotificationDropdown: !prevState.showNotificationDropdown,
    }));
  };

  render() {
    const {
      currentLead,
      currentLeadIndex,
      selectedComponetOrder,
      isFetchingLeadComments,
    } = this.state;
    const { user, leads, fetchLeadsOfLoggedinUser, sdfcDetails } = this.props;
    return (
      <div>
        <div className="welcomeDiv d-flex flex-reverse justify-content-between mb-5 headerContainer">
          <img src={Logo} alt="Regalix" className="headerLogo" />
          <h5>
            <span className="notification-container">
              <img
                src={Notification}
                alt="notification icon"
                height={30}
                className="notification-icon"
                onClick={this.toggleNotificationDropdown}
              />
              {this.state.notificationCount > 0 && (
                <span className="notification-badge">
                  {this.state.notificationCount}
                </span>
              )}
            </span>
            {this.state.showNotificationDropdown && (
              // render the notification dropdown here
              <div className="notification-dropdown">
                {this.state.notifications.length > 0 ? (
                  this.state.notifications.map((notification, ind) => {
                    return (
                      <div className="notification-msg" key={ind}>
                        <p
                          onClick={() => {
                            this.selectCurrentLead(notification.lead_index);
                            this.toggleNotificationDropdown();
                          }}
                        >
                          {notification.msg}
                        </p>
                        <span className="notification-date">
                          {notification.created_at}
                        </span>
                      </div>
                    );
                  })
                ) : (
                  <p className="empty-notification">Nothing to show</p>
                )}
              </div>
            )}
            Welcome <strong>{user.name}</strong>
            <span className="logout-button" title="Logout">
              <img
                src={Logout}
                alt="logout"
                height={30}
                onClick={this.props.handleLogout}
              />
            </span>
          </h5>
        </div>
        <div className="col-md-12 d-flex divHeight100 rx-padding-2-rem">
          <div className="col-md-2 divHeight100 me-2 hand-pointer w-5 black-background">
            <div
              onClick={() => this.alterComponentSelection(1)}
              className={`d-flex justify-content-center p-3 ${
                selectedComponetOrder === 1 && "activeTab"
              }`}
            >
              <img src={Leads} alt="leads" className="leftMenuIcons" />
            </div>
            <div
              onClick={() => this.alterComponentSelection(2)}
              className={`d-flex justify-content-center p-3 ${
                selectedComponetOrder === 2 && "activeTab"
              }`}
            >
              <img src={Uploads} alt="Uploads" className="leftMenuIcons" />
            </div>
            <div
              onClick={() => this.alterComponentSelection(3)}
              className={`d-flex justify-content-center p-3 ${
                selectedComponetOrder === 3 && "activeTab"
              }`}
            >
              <img
                src={ChartIcon}
                alt="Charts"
                className="leftMenuIcons leftMenuIcons--chart-icon"
              />
            </div>
          </div>
          {selectedComponetOrder === 1 && leads.length > 0 && (
            <>
              <div className="col-md-3 divHeight100 me-2 hand-pointer">
                <Lead
                  leads={leads}
                  selectCurrentLead={this.selectCurrentLead}
                  currentLeadIndex={currentLeadIndex}
                  allTasks={this.state.allTasks}
                />
              </div>

              <div className="w-100 rx-border-color rx-lead-details">
                <LeadDetails
                  currentLead={currentLead}
                  currentLeadIndex={currentLeadIndex}
                  selectCurrentLead={this.selectCurrentLead}
                  sdfcDetails={sdfcDetails}
                  setCurrentLeadComment={this.setCurrentLeadComment}
                  setCurrentLeadTaskStatus={this.setCurrentLeadTaskStatus}
                  isFetchingLeadComments={isFetchingLeadComments}
                />
              </div>
            </>
          )}
          {selectedComponetOrder === 2 && (
            <Upload
              user={user}
              fetchLeadsOfLoggedinUser={fetchLeadsOfLoggedinUser}
              alterComponentSelection={this.alterComponentSelection}
            />
          )}

          {selectedComponetOrder === 3 && <StatisticChartContainer />}
        </div>
      </div>
    );
  }
}

export default Task;
