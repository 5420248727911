import React from "react";
import { Button } from "react-bootstrap";
import jwt_decode from "jwt-decode";
import { getLoggedinUser } from "./assets/service";
import CallButton from "../CallModule/CallButton/CallButton";
import Task from "../Task/Task";

import Logo from "../Task/assets/images/logo.png";
import {
  getLocalStorageWithExpiration,
  setLocalStorageWithExpiration,
} from "../Task/assets/helpers/helper";
import loadingGif from "../../Components/CallModule/CallButton/assets/images/loading-gif.gif";

const { REACT_APP_GOOGLE_LOGIN_CLIENT_KEY } = process.env;

class Login extends React.Component {
  constructor(props) {
    super(props);
    const { user, needManualLogin } = this.props;
    this.state = {
      loggedInUser: needManualLogin === 1 ? false : true,
      user,
      leads: [],
      sdfcDetails: {
        access_token: "",
        instance_url: "",
      },
      isLoading: false,
      syncAccessToken: "",
    };
  }

  componentDidMount() {
    this.setState({ ...this.state, isLoading: true });
    const userData = getLocalStorageWithExpiration("user");
    if (userData !== null) {
      const user = JSON.parse(userData);
      this.fetchLeadsOfLoggedinUser(user.email_id);
    } else {
      this.setState({
        ...this.state,
        isLoading: false,
      });
      this.createGoogleClient();
    }
  }

  handleCallBack = async (response) => {
    const userObj = await jwt_decode(response.credential);
    if (userObj.email_verified) {
      this.fetchLeadsOfLoggedinUser(userObj.email);
    }
  };

  fetchLeadsOfLoggedinUser = (email) => {
    return new Promise(async (resolve) => {
      const getLoggedinUserResponse = await getLoggedinUser(email);
      const tempUser = {
        id: getLoggedinUserResponse.data.user.id,
        name: getLoggedinUserResponse.data.user.name,
        number: getLoggedinUserResponse.data.user.phone_number.number,
        identity: getLoggedinUserResponse.data.user.user_identity,
        tenant_id: getLoggedinUserResponse.data.user.tenant_id,
        subtenant_id: getLoggedinUserResponse.data.user.subtenant_id,
        email_id: email,
      };

      // localStorage.setItem("user", JSON.stringify(tempUser));
      setLocalStorageWithExpiration(
        "user",
        JSON.stringify(tempUser),
        1440 // 24 hours
      );

      this.setState(
        {
          ...this.state,
          user: { ...tempUser },
          leads: [...getLoggedinUserResponse.data.leads],
          isLoading: false,
        },
        () => {
          this.setState({ ...this.state, loggedInUser: true }, () =>
            resolve(true)
          );
        }
      );
    });
  };

  createGoogleClient = () => {
    google.accounts.id.initialize({
      client_id: REACT_APP_GOOGLE_LOGIN_CLIENT_KEY,
      callback: this.handleCallBack,
    });

    google.accounts.id.renderButton(document.getElementById("singleDiv"), {
      theme: "outline",
      size: "large",
    });
  };

  responseGoogle = (response) => {
    console.log(response);
  };

  setSdfcDetails = (sfdccInfo, syncAccessToken) => {
    this.setState({
      ...this.state,
      sdfcDetails: { ...sfdccInfo },
      syncAccessToken,
    });
  };

  handleLogout = () => {
    localStorage.removeItem("user");
    this.setState({ ...this.state, loggedInUser: false });
    setTimeout(() => {
      this.createGoogleClient();
    }, 2000);
  };

  render() {
    const {
      loggedInUser,
      user,
      leads,
      sdfcDetails,
      isLoading,
      syncAccessToken,
    } = this.state;
    return (
      <div>
        {isLoading && (
          <div className="loader">
            <img src={loadingGif} alt="Loading" height={50} />
          </div>
        )}
        {!loggedInUser && !isLoading && (
          <div className="loginContainer">
            <div className="loginContainer loginItemContainer">
              <div className="row m-3 logoContainer">
                <img src={Logo} alt="" className="w-75" />
              </div>
              <div className="row m-5 logoContainer">
                <p>Regalix Contact Center</p>
              </div>
              <div id="singleDiv"></div>
            </div>
          </div>
        )}
        {loggedInUser && (
          <CallButton user={user} setSdfcDetails={this.setSdfcDetails} />
        )}
        {loggedInUser &&
          sdfcDetails.access_token !== "" &&
          syncAccessToken !== "" && (
            <Task
              user={user}
              leads={leads}
              fetchLeadsOfLoggedinUser={this.fetchLeadsOfLoggedinUser}
              sdfcDetails={sdfcDetails}
              syncAccessToken={syncAccessToken}
              handleLogout={this.handleLogout}
            />
          )}
      </div>
    );
  }
}

export default Login;
