/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import {
  barChartoptions,
  pieChartoptions,
  defaultBarChartData,
} from "../../assets/constant";
import moment from "moment-timezone";
import { getLocalStorageWithExpiration } from "../../assets/helpers/helper";
import {
  getBarChartData,
  getPieChartData,
  getLastNthDay,
  requestHeader,
} from "../helpers";

export const useHandleStatisticsFetching = () => {
  const [barChartdata, setBarChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [pieChartdata, setPieChartData] = useState({
    labels: [],
    datasets: [],
  });
  const [isLoading, setIsLoading] = useState(true);

  const onGetData = async (params = {}) => {
    try {
      setIsLoading(true);
      const userData = getLocalStorageWithExpiration("user");
      const { tenant_id, subtenant_id, number } = JSON.parse(userData);

      const config = requestHeader("/analytics/calls", "POST", {
        tenant_id,
        subtenant_id,
        phone_number: number,
        ...params,
      });

      const response = await axios(config);
      const displayedData = getBarChartData(response.data);

      setBarChartData(displayedData);

      const pieChartData = getPieChartData(response.data);
      setPieChartData(pieChartData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  useEffect(() => {
    const sourceTimeZone = moment.tz.zone(moment.tz.guess()).name;
    const fetchInitialData = async () => {
      const start_date = getLastNthDay(0);
      const sourceStartDateTime = `${start_date} 00:00:00`;
      const sourceEndDateTime = `${start_date} 23:59:59`;

      const convertedStartDate = moment
        .tz(sourceStartDateTime, sourceTimeZone)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");
      const convertedEndDate = moment
        .tz(sourceEndDateTime, sourceTimeZone)
        .utc()
        .format("YYYY-MM-DD HH:mm:ss");

      await onGetData({
        start_date: convertedStartDate,
        end_date: convertedEndDate,
      }); // default date is today
    };
    fetchInitialData();
  }, []);

  return {
    barChartdata,
    barChartoptions,
    pieChartdata,
    pieChartoptions,
    onGetData,
    isLoading,
    setIsLoading,
  };
};
