import React from "react";
import moment from "moment";
import Accordion from "react-bootstrap/Accordion";
import { Button } from "react-bootstrap";
import ContactInitial from "../Common/ContactInitial";
import MakeCallButton from "../Common/MakeCallButton";
import Call from "../assets/images/log_a_call.svg";
import Note from "../assets/images/log_a_note.svg";
import loadingGif from "../../../Components/CallModule/CallButton/assets/images/loading-gif.gif";

class LeadDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentText: "",
      commentTitle: "",
      updatedCallSummary: "",
      callSummaryEditable: false,
      activeAccordionItem: null,
    };
  }

  componentDidUpdate(prevProps) {
    const arePropsEqual =
      this.props.currentLead.leadDetails.number ===
      prevProps.currentLead.leadDetails.number;
    if (!arePropsEqual) {
      this.setState({
        ...this.state,
        updatedCallSummary: "",
        callSummaryEditable: false,
      });
    }
  }

  prepareMakingAddComment = () => {
    const { setCurrentLeadComment, currentLead } = this.props;
    const { commentText, commentTitle } = this.state;
    setCurrentLeadComment(
      currentLead.leadDetails.number,
      commentText,
      commentTitle
    );
    this.setState({ ...this.state, commentText: "", commentTitle: "" });
  };

  setCommentText = (e) => {
    const { value, name } = e.target;
    this.setState({ ...this.state, [name]: value });
  };

  updateStatus = (conferenceSid, description, taskStatus) => {
    const { setCurrentLeadTaskStatus, currentLead } = this.props;
    setCurrentLeadTaskStatus(
      currentLead.leadDetails.number,
      conferenceSid,
      description,
      taskStatus
    );
    this.setState({ ...this.state, callSummaryEditable: false });
  };

  handleCallSummaryEditable = (
    summary = "",
    editable = false,
    accordion_id = null
  ) => {
    this.setState({
      ...this.state,
      updatedCallSummary: summary !== "" ? summary : "",
      callSummaryEditable: editable,
      activeAccordionItem: accordion_id,
    });
  };

  handleOnChangeCallSummary = (e) => {
    const { value } = e.target;
    this.setState({ ...this.state, updatedCallSummary: value });
  };

  render() {
    const {
      currentLead,
      selectCurrentLead,
      currentLeadIndex,
      isFetchingLeadComments,
    } = this.props;
    const { commentText, commentTitle } = this.state;
    return (
      typeof currentLead !== "undefined" &&
      Object.values(currentLead.leadDetails).length > 0 && (
        <div className="card lead-details">
          <div className="card-header rx-card-header py-4">
            <div className="d-flex">
              <div className="me-3">
                <ContactInitial
                  leadName={currentLead.leadDetails.name}
                  spanPadding="p-4"
                />
              </div>
              <div className="d-flex align-items-center w-100 justify-content-between">
                <div>
                  <div className="name">{currentLead.leadDetails.name}</div>
                  <div className="address">
                    {currentLead.leadDetails.address}
                  </div>
                </div>
                <MakeCallButton
                  leadNumber={currentLead.leadDetails.number}
                  leadName={currentLead.leadDetails.name}
                  leadIndex={currentLeadIndex}
                  selectCurrentLead={selectCurrentLead}
                />
              </div>
            </div>
          </div>
          <div className="card-body">
            <p className="card-title mb-5">
              {currentLead.leadDetails.description}
            </p>
            <p className="card-text mb-1">
              <strong>Service Options: </strong>
              <span>{currentLead.leadDetails.service_option}</span>
            </p>
            <p className="card-text mb-1">
              <strong>Hours: </strong>
              <span>
                Open{" "}
                {
                  currentLead.leadDetails.communication_preferences.call
                    .preferred_time.start
                }{" "}
                - Closes{" "}
                {
                  currentLead.leadDetails.communication_preferences.call
                    .preferred_time.end
                }{" "}
                {
                  currentLead.leadDetails.communication_preferences.call
                    .preferred_time.tz
                }
              </span>
            </p>
          </div>
          <Accordion className="mb-2">
            <Accordion.Item>
              <Accordion.Header className="rx-accordian-add-comment">
                Enter New Comment
              </Accordion.Header>
              <Accordion.Body>
                <div className="card mb-2">
                  <input
                    type="text"
                    name="commentTitle"
                    id="commentTitle"
                    placeholder="Enter Title"
                    onChange={(e) => this.setCommentText(e)}
                    className="form-control"
                    value={commentTitle}
                  />
                </div>
                <div className="card">
                  <div>
                    <textarea
                      name="commentText"
                      id="commentText"
                      cols="30"
                      rows="10"
                      className="w-100 form-control mb-2"
                      onChange={(e) => this.setCommentText(e)}
                      value={commentText}
                      placeholder="Enter Details"
                    >
                      {commentText}
                    </textarea>
                  </div>
                  <div className="d-flex justify-content-center mb-2">
                    <Button onClick={() => this.prepareMakingAddComment()}>
                      Add Comment
                    </Button>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <div className="card">
            <div className="card-header rx-card-header rx-no-border-radius">
              Communication History
            </div>
            <div className="card-body">
              <Accordion defaultActiveKey="0">
                {isFetchingLeadComments && (
                  <div className="rx-communication-history-loader">
                    <img src={loadingGif} alt="Loading" />
                  </div>
                )}
                {!isFetchingLeadComments &&
                  currentLead.comments.map((element, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header
                        className={`${
                          element.Body !== null
                            ? "accordianBodyHasContent"
                            : "noBodyContent"
                        }`}
                      >
                        {element.Status === "Draft" && (
                          <span className="call-notification-badge notification-badge"></span>
                        )}
                        <img
                          src={element.Type === "Note" ? Note : Call}
                          alt=""
                          className={`w-100 ${
                            element.Type === "Note"
                              ? "descMakeCallBtn"
                              : "descNoteBtn"
                          }`}
                        />
                        <div className="mx-2 rx-communication-header">
                          <div className="text-secondary">
                            {element.Title} - ({element.CreatedBy})
                          </div>
                          <div className="text-secondary">
                            {moment(element.CreatedDate).local().format("llll")}
                          </div>
                        </div>
                      </Accordion.Header>
                      {element.Body !== null && (
                        <Accordion.Body>
                          {this.state.callSummaryEditable &&
                          this.state.activeAccordionItem === index ? (
                            <div>
                              <textarea
                                className="form-control"
                                value={this.state.updatedCallSummary}
                                rows={5}
                                onChange={(e) =>
                                  this.handleOnChangeCallSummary(e)
                                }
                                style={{
                                  minHeight: "300px",
                                }}
                              ></textarea>
                              <div className="button-container">
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() =>
                                    this.updateStatus(
                                      element.ConferenceSid,
                                      this.state.updatedCallSummary,
                                      "Complete"
                                    )
                                  }
                                >
                                  {element.Status === "Draft"
                                    ? "Save & Appove"
                                    : "Save"}
                                </button>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() =>
                                    this.handleCallSummaryEditable(
                                      element.Body,
                                      false,
                                      null
                                    )
                                  }
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <pre style={{ whiteSpace: "pre-wrap" }}>
                                {element.Body}
                              </pre>
                              {element.Type === "Call" && (
                                <div className="button-container">
                                  <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() =>
                                      this.handleCallSummaryEditable(
                                        element.Body,
                                        true,
                                        index
                                      )
                                    }
                                  >
                                    {element.Status === "Draft"
                                      ? "Edit & Appove"
                                      : "Edit"}
                                  </button>
                                  {element.Status === "Draft" && (
                                    <button
                                      className="btn btn-success btn-sm"
                                      onClick={() =>
                                        this.updateStatus(
                                          element.ConferenceSid,
                                          element.Body,
                                          "Complete"
                                        )
                                      }
                                    >
                                      Approve
                                    </button>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                        </Accordion.Body>
                      )}
                    </Accordion.Item>
                  ))}
              </Accordion>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default LeadDetails;
