import React from "react";

import DataFilterBar from "./DataFilterBar";
import { useContext } from "react";
import StatisticAPIContext from "./contexts/StatisticAPIContext";
import { useHandleStatisticsFetching } from "./customHooks/useHandleStatisticsFetching";
import "./stats.css";
import LoadingScreen from "./LoadingScreen";
import BarChart from "./BarChart";
import PieChart from "./PieChart";

const StatisticalCharts = () => {
  const {
    barChartdata,
    barChartoptions,
    pieChartdata,
    pieChartoptions,
    isLoading,
  } = useContext(StatisticAPIContext);

  return (
    <div className="tab-chart-container">
      <LoadingScreen isLoading={isLoading} />
      <DataFilterBar />
      <div className="chart-container">
        {/* <Bar options={options} data={data} /> */}

        <div className="col-md-4 card card-container">
          <div className="card-body">
            <h5 className="card-title">Total Calls</h5>
            <PieChart data={pieChartdata} option={pieChartoptions} />
          </div>
        </div>
        <div className="col-md-8 card card-container margin-left-20">
          <div className="card-body">
            <h5 className="card-title">Calls Per Lead</h5>
            <BarChart options={barChartoptions} data={barChartdata} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const StatisticChartContainer = () => {
  const apiParams = useHandleStatisticsFetching();

  return (
    <StatisticAPIContext.Provider value={apiParams}>
      <StatisticalCharts />
    </StatisticAPIContext.Provider>
  );
};
