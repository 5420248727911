import React from "react";
import ContactInitial from "../Common/ContactInitial";
import MakeCallButton from "../Common/MakeCallButton";
import chevronRight from "../assets/images/chevron-right.svg";
import searchicon from "../assets/images/searchicon.svg";
const { REACT_APP_STATIC_ASSETS_BASE_URL } = process.env;

class Lead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredLeads: props.leads,
      searchText: "",
    };
  }

  render() {
    const { leads, selectCurrentLead, currentLeadIndex } = this.props;
    const { filteredLeads, searchText } = this.state;
    const filterLeads = (searchText) => {
      const filteredData = leads.filter(
        (item) =>
          item.name.toLowerCase().includes(searchText.toLowerCase()) ||
          item.address
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
      this.setState({ filteredLeads: filteredData, searchText: searchText });
    };

    const handleGetNotificationCount = (phoneNumber) => {
      const { allTasks } = this.props;
      let draftCount = 0;
      if (Object.keys(allTasks).length > 0) {
        const tasks = allTasks[phoneNumber];
        draftCount = 0;
        if (typeof tasks !== "undefined") {
          for (const task of tasks) {
            if (task.Status === "Draft") {
              draftCount++;
            }
          }
        }
      }
      return draftCount;
    };
    return (
      <div className="card mx-2 no-border">
        <div className="searchboxsec">
          <input
            type="text"
            value={searchText}
            placeholder="Search Leads"
            onChange={(event) => {
              filterLeads(event.target.value);
            }}
          />
          <img
            alt=""
            src={`${REACT_APP_STATIC_ASSETS_BASE_URL}${searchicon}`}
          />
        </div>
        {filteredLeads.length === 0 && searchText && (
          <p className="text-center">No results found.</p>
        )}
        {filteredLeads.length > 0 && (
          <ul className="list-group list-group-flush">
            {filteredLeads.map((lead, index) => (
              <li
                className={`list-group-item ${
                  currentLeadIndex === index
                    ? "activeLaedCart"
                    : "inactiveLaedCart"
                }`}
                key={index}
              >
                <div className="col-md-12 d-flex p-relative">
                  {handleGetNotificationCount(lead.number) > 0 && (
                    <span className="lead-notification-badge notification-badge">
                      {handleGetNotificationCount(lead.number)}
                    </span>
                  )}
                  <div className="me-2 col-md-2 d-flex align-items-center">
                    <ContactInitial leadName={lead.name} spanPadding="p-2" />
                  </div>
                  <div
                    className="col-md-8 user-select hand-pointer"
                    onClick={() => selectCurrentLead(index)}
                  >
                    <div className="name">{lead.name}</div>
                    <div className="address">{lead.address}</div>
                    <div className="hours">
                      Hours:{" "}
                      {lead.communication_preferences.call.preferred_time.start}{" "}
                      -{lead.communication_preferences.call.preferred_time.end}{" "}
                      {lead.communication_preferences.call.preferred_time.tz}
                    </div>
                  </div>
                  {currentLeadIndex === index ? (
                    <img className="rounded-circle w-100" src={chevronRight} />
                  ) : (
                    <MakeCallButton
                      leadName={lead.name}
                      leadNumber={lead.number}
                      shouldHideContactNumber={true}
                      leadIndex={index}
                      selectCurrentLead={selectCurrentLead}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default Lead;
