import axios from "axios";
import { urls } from "../constant";
import moment from "moment";
const { REACT_APP_API_BASE_URL } = process.env;

export const displayContactInitial = (contactName) => {
  let names =
    contactName === null || typeof contactName === "undefined"
      ? "Call"
      : contactName.split(" ");
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const startUploadingLeadFile = (formData) => {
  var config = {
    method: urls.uploadLeadFile.method,
    url: `${REACT_APP_API_BASE_URL}${urls.uploadLeadFile.url}`,
    headers: {
      "Content-Type": "multipart/form-data",
      "Content-Disposition": 'attachment; filename="' + Date.now() + '.mp3"',
    },
    data: formData,
  };
  return axios(config);
};

export const fetchFeedForLead = (
  instanceUrl,
  token,
  objectName,
  phoneNumber
) => {
  const config = {
    method: urls.salesForceGetFeed.method,
    url: `${instanceUrl}${urls.salesForceGetFeed.url}`,
    headers: {
      Authorization: `Bearer ${token}`,
      ObjectName: objectName,
      PhoneNumber: phoneNumber,
    },
  };
  return axios(config);
};

export const addFeedForLead = (
  instanceUrl,
  token,
  phoneNumber,
  content,
  title
) => {
  const config = {
    method: urls.salesForcePostFeed.method,
    url: `${instanceUrl}${urls.salesForcePostFeed.url}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { content, phoneNumber, title },
  };
  return axios(config);
};

export const updateTaskForLead = (
  instanceUrl,
  token,
  conferenceSid,
  description,
  status
) => {
  const config = {
    method: urls.salesForceUpdateTask.method,
    url: `${instanceUrl}${urls.salesForceUpdateTask.url}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { conferenceSid, description, status },
  };
  return axios(config);
};

export const getAllTasksFromSalesforce = (instanceUrl, token, phoneNumbers) => {
  const config = {
    method: urls.salesForceGetAllTask.method,
    url: `${instanceUrl}${urls.salesForceGetAllTask.url}?phoneNumbers=${phoneNumbers}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return axios(config);
};

export const setLocalStorageWithExpiration = (
  key,
  data,
  expirationInMinutes
) => {
  const expirationDate = moment().add(expirationInMinutes, "minutes");
  const item = {
    data: data,
    expiration: expirationDate.valueOf(),
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getLocalStorageWithExpiration = (key) => {
  const item = JSON.parse(localStorage.getItem(key));
  if (item) {
    const currentTime = moment().valueOf();
    if (currentTime < item.expiration) {
      return item.data;
    } else {
      localStorage.removeItem(key);
    }
  }
  return null;
};
